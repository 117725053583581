@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-header {
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 16px 16px 40px;
}

.App-link {
  color: #61dafb;
}

.row-large {
  display: flex;
  gap: 46px;
}

.row {
  display: flex;
  gap: 16px;
}

.stack {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
